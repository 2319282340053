
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
   

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      // tits:obj.el.parentNode.parentNode.querySelectorAll('.ttj')
     
    }


    this.active = 0
    this.postit = 0
    this.isstarted = 0
    this.h = window.innerHeight
    


    // this.navhead = document.querySelector('.nav_head')

  }
  
  async create(){
      this.DOM.sizers = document.querySelectorAll('.home_intro .content_hide span')
      this.DOM.tits = document.querySelectorAll('.home_intro .cloned')
      this.DOM.holder = document.querySelector('.home_intro .holdwords')
    
    this.changeTit = () =>{

      for(let [i,a] of this.DOM.tits[this.postit].querySelectorAll('.char').entries()){
     
        // gsap.to(a,{rotateY:-60+'deg',opacity:0,duration:.4})
        gsap.to(a,{opacity:0,duration:.4})
  
      }
      gsap.set(this.DOM.tits[this.postit],{display:'none',delay:.41})
      
      this.postit++
      if(this.postit==this.DOM.tits.length){
        this.postit = 0
      }

      gsap.to(this.DOM.holder,{width:this.DOM.sizers[this.postit].clientWidth+'px',delay:.4,duration:.6})
      gsap.set(this.DOM.tits[this.postit],{display:'inline-block'})


      for(let [i,a] of this.DOM.tits[this.postit].querySelectorAll('.char').entries()){
        // gsap.fromTo(a,{rotateY:0+'deg',rotateX:115+'deg',opacity:0},{rotateX:0+'deg',opacity:1,duration:.8,delay:1})
        gsap.fromTo(a,{rotateX:0+'deg',opacity:0},{rotateX:0+'deg',opacity:1,duration:.8,delay:1+i*.1})
      }

  
    }
    
    // this.DOM.tits[0].classList.add('act')
    
    
    // this.anim.paused()
    
  }

  createAnim(){
    this.anim = gsap.set(this.changeTit, {onRepeat: this.changeTit, repeat: -1, repeatDelay: 4})
    
  }

  killAnim(){
    if(this.isstarted == 0 || !this.anim) return false
    this.anim.kill()
  }

  

  check(entry,pos){
    let vis = false

      if(entry.isIntersecting == true){
       
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }
  
  start(){
    if(this.active==1){
      return false
    }
    this.active = 1
    this.killAnim()
    this.createAnim()
  }
  
  stop(){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }
    this.active = 0
    this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }

  initEvents(){
    this.startFn = ()=>{
      if(this.active==1){
        this.killAnim()
        this.createAnim()
      }
    }

   
    if(!this.touch ){
      window.addEventListener('focus',this.startFn)
      
    }

  }
  removeEvents(){
    if(!this.touch ){
      window.removeEventListener('focus',this.startFn)
      
    }

  }
  touchScroll(){

  }
  update(pos){
   
  }

  onResize(pos){

    // let h = 0
    // for(let a of this.DOM.tits){
    //   if(h < a.clientHeight){
    //     h = a.clientHeight
    //   }


    // }
    // this.DOM.tits[0].parentNode.style.height = h+'px'

   
  }
  
  
}
