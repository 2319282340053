import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

import { clamp, lerp } from '/js🧠🧠🧠/defaults/math🧮.js'

//COMPS
import Intro from './0Intro'
import Mainform from './1Main'





class Home extends Page {
  constructor (main,footer) {
    super(main,footer)
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){

      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{
      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.html)

    }
    
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el
    }

    await this.loadImages()
    await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    // if(animobj.el.classList.contains('iO-intro')){
    //   animobj.class = new Introio(animobj,this.main.device,this.main.isTouch) 
      

    // }
    // else if(animobj.el.classList.contains('iO-logos')){
    //   animobj.class = new Logos(animobj,this.main.device,this.main.isTouch) 
      

    // }
    // else if(animobj.el.classList.contains('iO-cards')){
    //   animobj.class = new Cards(animobj,this.main.device,this.main.isTouch) 
      

    // }
    // else if(animobj.el.classList.contains('iO-Process')){
    //   animobj.class = new Process(animobj,this.main.device,this.main.isTouch) 
      

    // }
    // else if(animobj.el.classList.contains('iO-ProcessBg')){
    //   animobj.class = new ProcessBg(animobj,this.main.device,this.main.isTouch) 
      

    // }
    return animobj
  }

  
  
  async createComps(){
    await super.createComps()
    if(this.DOM.el.querySelector('.contact_intro')){
      this.components.intro = new Intro(this.DOM.el.querySelector('.contact_intro'))
    }
    if(this.DOM.el.querySelector('.contact_intro form')){
      this.components.form = new Mainform(this.DOM.el.querySelector('.contact_intro'),this.main.base)
    }
    

  }


  async animIntro(val){
    if(this.components.intro){
      this.components.intro.start()
    }


    return val
   
  }

  async animOut(){
   await gsap.to(this.DOM.el,{opacity:0})

  }

}




export default Home