
// import './index.scss'

export default class {
  constructor (el) {

    this.el = el    
   

    // this.device = device
    // this.touch = touch
    this.DOM = {
      el:el,
      elsclick:el.querySelectorAll('.el_click')
    }


    this.pos = 0
    this.create()
  }
  
  create(){
    this.DOM.elsclick[this.pos].parentNode.classList.add('act')
  
  }


  

  initEvents(){
   for(let [i,a] of this.DOM.elsclick.entries()){
    a.onclick = () =>{
      this.DOM.elsclick[this.pos].parentNode.classList.remove('act')
      this.pos = i
      this.DOM.elsclick[this.pos].parentNode.classList.add('act')
    }
   }

  }
  removeEvents(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
