
import './index.scss'

export default class {
  constructor (el,device) {

    this.el = el    
   

    this.device = device
    // this.touch = touch
    if(this.device > 1){
      return false
    }

    this.DOM = {
      el:el,
      elsclick:el.querySelectorAll('.selectors_el'),
      els:el.querySelectorAll('.content_slider_el')
    }


    this.pos = 0
    this.create()
  }
  
  create(){
    this.DOM.elsclick[this.pos].classList.add('act')
    this.DOM.els[this.pos].classList.add('act')
  
  }


  

  initEvents(){
    if(this.device > 1){
      return false
    }
   for(let [i,a] of this.DOM.elsclick.entries()){
    a.onclick = () =>{
      this.DOM.elsclick[this.pos].classList.remove('act')
      this.DOM.els[this.pos].classList.remove('act')
      this.pos = i
      this.DOM.elsclick[this.pos].classList.add('act')
      this.DOM.els[this.pos].classList.add('act')
    }
   }

  }
  removeEvents(){

  }
  update(pos){
   
  }

  onResize(pos){
    if(this.device > 1){
      return false
    }
    let h = 0
    for(let [i,a] of this.DOM.els.entries()){
      if(a.clientHeight > h){
        h = a.clientHeight
      }
    }

    this.DOM.els[0].parentNode.style.height = h+'px'


  }
  
  
}
