
export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      tt:el.querySelector('.ttj'),
    }
    this.device = device
    this.active = 0
    this.create()
  }

  async create(){
    
   
    this.anim = gsap.timeline({paused:true})
    if(this.device > 1){
      this.anim
      // .fromTo(this.DOM.el.querySelector('.ttj'),{opacity:0},{x:0,opacity:1,duration:.4,ease:Power2.easeInOut},0)
      // .fromTo(this.DOM.el.querySelector('p'),{opacity:0},{x:0,opacity:1,duration:.4,ease:Power2.easeInOut},.2)
      // .fromTo(this.DOM.el.querySelector('.bg'),{scale:.5,opacity:0},{opacity:1,scale:1,duration:.4,ease:Power2.easeInOut},0)
      // .fromTo(this.DOM.el.querySelector('img'),{opacity:0},{opacity:1,duration:.4,ease:Power2.easeInOut},0)
      // .to(this.DOM.el.querySelector('.cone'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
      // .to(this.DOM.el.querySelector('.torus'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
    }
    else{
      this.anim
      .fromTo(this.DOM.el.querySelector('.ttj'),{opacity:0,x:-1.6+'rem'},{x:0,opacity:1,duration:.6,ease:Power2.easeInOut},0)
      .fromTo(this.DOM.el.querySelector('p'),{opacity:0,x:-1.6+'rem'},{x:0,opacity:1,duration:.6,ease:Power2.easeInOut},.05)
      .fromTo(this.DOM.el.querySelector('.bg'),{rotate:-12+'deg',scale:.5,opacity:0},{opacity:1,rotate:0+'deg',scale:1,duration:.6,ease:Power2.easeInOut},.3)
      
    }
    

    
  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
