

export function addPop () {

  //window.addEventListener('popstate', this.onPopState, { passive: true })
  
}



  // EVENTS

  // Patrás y palante
export function onPopState () {
  //Revisa punto 2 en checks
    this.onChange({
      url: window.location.pathname,
      push: false
    })
}



  // Empieza el cambio
export async function onChange ({ url = null, link = null, id = null, img = null }) {
    url = url.replace(window.location.origin, '')
    if (this.isLoading || this.url === url) return
    this.lenis.stop()
    this.issame = 0
    this.page.isVisible = false
    this.isLoading = true
    let time = 12
    
    this.url = url

    let functowait = []
    if(this.nav.isOpen==1){
      this.nav.closeMenu()
      this.nav.isOpen=0
      time = 12
    }
    // functowait.push()
    // await this.page.hide()
    
    document.body.style.pointerEvents = 'none'

    const request = await window.fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })

    const response = await request.text()
    var push = true


    if(this.canvas){
      this.canvas.cleanTemp()
    }
    await this.page.animOut()
    this.loader.showPop()

	  Promise.all([
      this.timeout(time),
      this.onRequest({
        push,
        response,
        url,
        id
      })
    ]).then(()=>{
      this.newView()

    })

  }

  // llamada de la nueva URL
export async function onRequest ({ push, response, url, id }) {
    const html = document.createElement('div')

    html.innerHTML = response
    if(html.querySelector('title')){
      document.title = html.querySelector('title').textContent
    }
    this.content = html.querySelector('#content')
    

    if (push) {
      window.history.pushState({}, document.title, url)
    }

    await this.page.hide()
    this.lenis.scrollTo(0,{immediate:true,lock:true,force:true})
    this.page.DOM.el.remove()
    
    

    this.template = this.content.dataset.template
    this.newpage = this.pages.get(this.template)
    this.newpage.id = this.content.dataset.id

    this.newpage.ispop = 1
    await this.newpage.create(this.content,this.main)
    if(this.canvas){
      await this.canvas.createTemp(this.template)
    }
    
}


export async function newView(){
    
    
     
    // console.log('estoy aquí')
    // this.loader.hide()

    if(this.mouse){
      this.mouse.reset()
    }

    
    document.body.style.pointerEvents = ''
    this.isLoading = false
    
    this.loader.hidePop()
    this.newpage.show(0)

    if(this.canvas){
      this.canvas.show()
    }

    this.page = this.newpage
    let state = this.page.start(0)

    this.newpage.ispop = 0
    


    this.addControllers()
    this.lenis.start()

}


//CHECKS 
//1.Onchange
//2.Pops palante y patrás
////3.tener en cuenta menú abierto o modales
////4.tener en cuenta transiciones entre vistas del mismo tipo ( de post a post, por ejemplo )