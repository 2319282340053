
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      tt:el.querySelector('.ttj'),
      hides:el.querySelectorAll('.content_hide span')
    }
    this.device = device
    this.active = 0
    
  }

  async create(){
    
    this.split = new window.SplitType(this.DOM.tt.querySelector('span'), {types: 'chars' })
    this.DOM.tt.querySelector('span').classList.add('holdwords')
    const noreveal = this.DOM.tt.querySelector('span').querySelectorAll('.char')
    let clonedfirst = ''
    let cloned = ''
    for(let [i,a] of this.DOM.hides.entries()){
      cloned = a.cloneNode(true)
      this.DOM.tt.querySelector('span').insertAdjacentElement('beforeend',cloned)
      let split = new window.SplitType(cloned, {types: 'chars' })
      for(let [u,b] of cloned.querySelectorAll('.char').entries()){
     
        // gsap.set(b,{rotateX:115+'deg',opacity:0})
        gsap.set(b,{opacity:0})
  
      }
      cloned.classList.add('cloned')
      cloned.style.position = ''

      if(i==0){
        clonedfirst = cloned
      }
    }
    this.anim = gsap.timeline({paused:true})
    if(this.device > 1){
      this.anim
      .set(this.DOM.tt,{opacity:1},0)
      // .to(this.DOM.el.querySelector('.cone'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
      // .to(this.DOM.el.querySelector('.torus'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
    }
    else{
      this.anim
      .fromTo(this.DOM.tt,{opacity:0},{opacity:1,duration:.6,delay:.8,ease:Power2.easeInOut},0)
      .to(this.DOM.el.querySelector('.cone'),{yPercent:-100,duration:2,delay:1.4,ease:'none'},0)
      .to(this.DOM.el.querySelector('.torus'),{yPercent:-100,duration:2,delay:1.4,ease:'none'},0)
      .to(this.DOM.el.querySelector('.logostart'),{top:70+'%',ease:Power2.easeInOut,duration:2},0)
    }
    


    this.anim.set(clonedfirst,{display:'inline-block'},1.4)
    for(let [i,a] of clonedfirst.querySelectorAll('.char').entries()){
     
      // this.anim.fromTo(noreveal[i],{rotateY:0+'deg',opacity:1},{rotateY:-60+'deg',opacity:0,duration:.4},1.4)
      // this.anim.fromTo(a,{rotateX:115+'deg',opacity:0},{rotateX:0+'deg',opacity:1,duration:.8},1.6+(i*.1))

      this.anim.fromTo(noreveal[i],{opacity:1},{opacity:0,duration:.4},1.4)
      this.anim.fromTo(a,{opacity:0},{opacity:1,duration:.8},1.7+(i*.1))

    }
    
  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
