
//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from '/views👁️👁️👁️/⚪Home/home.js'
import Enterprise from '/views👁️👁️👁️/🔵Enterprise/enterprise.js'
import Ai from '/views👁️👁️👁️/🟢Ai/ai.js'
import About from '/views👁️👁️👁️/🟣About/about.js'
import News from '/views👁️👁️👁️/📚News/news.js'
import Post from '/views👁️👁️👁️/📖Post/post.js'
import Contact from '/views👁️👁️👁️/📩Contact/contact.js'
import Legal from '/views👁️👁️👁️/🔗Legal/legal.js'
import Error from '/views👁️👁️👁️/🚫404/404.js'


import '/views👁️👁️👁️/⚪Home/styles.js'
import '/views👁️👁️👁️/🔵Enterprise/styles.js'
import '/views👁️👁️👁️/🟢Ai/styles.js'
import '/views👁️👁️👁️/🟣About/styles.js'
import '/views👁️👁️👁️/📚News/styles.js'
import '/views👁️👁️👁️/📖Post/styles.js'
import '/views👁️👁️👁️/📩Contact/styles.js'
import '/views👁️👁️👁️/🚫404/styles.js'

// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Project from '/views👁️👁️👁️/Project🟢'
// import Contact from '/views👁️👁️👁️/Contact🔵'


// import Legal from '/views👁️👁️👁️/🔗Legal'

  




export function createViews(){


  this.pages = new Map()
    this.pages.set('home', new Home(this.main))
    this.pages.set('enterprise', new Enterprise(this.main))
    this.pages.set('ai', new Ai(this.main))
    this.pages.set('about', new About(this.main))
    this.pages.set('news', new News(this.main))
    this.pages.set('post', new Post(this.main))
    this.pages.set('page', new Legal(this.main))
    this.pages.set('contact', new Contact(this.main))
    this.pages.set('404', new Error(this.main))

    // if(import.meta.env.DEV == false){

    // }
    // else{
    //   // this.pages.set('home', '/views👁️👁️👁️/⚪Home/home.js')
    //   // this.pages.set('enterprise', '/views👁️👁️👁️/🔵Enterprise/enterprise.js')
    //   // this.pages.set('ai', '/views👁️👁️👁️/🟢Ai/ai.js')
    //   // this.pages.set('news', '/views👁️👁️👁️/📚News/news.js')
    //   this.pages.set('home', './home.js')
    //   this.pages.set('enterprise', './enterprise.js')
    //   this.pages.set('ai', './ai.js')
    //   this.pages.set('news', './news.js')

    // }

}

