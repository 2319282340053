
import { timeout } from '/js🧠🧠🧠/defaults/math🧮.js'
import './index.scss'



class Loader {
  constructor (main,temp,device) {
    
    this.main = main
    this.counter = 0
    this.index = 0
    this.temp = {init:temp,pop:temp}
    this.device = device
    
  }
  async create(){
    document.querySelector('body').insertAdjacentHTML('afterbegin',this.temp.init)
    
    this.DOM = {
      el: document.documentElement.querySelector('.loader'),
      
    }
    
    // gsap.set(this.DOM.el.querySelector('#logop3 path'),{scaleY:0})
    this.createAnim()
  }
  
  createAnim(){
    this.rotate ={value:0}
    this.anim = gsap.timeline({paused:true})
    this.anim.fromTo(this.DOM.el.querySelector('#logop2'),{rotate:0},{rotate:1080+'deg',duration:12},0)
    this.anim.fromTo(this.rotate,{value:0},{value:1080,duration:12},0)
  }
  

  async hide(){

  }
  async show(){
  }
  async start () {

    this.anim.play()
  }

  async showPop(){

    if(this.device > 1){
      document.querySelector('body').insertAdjacentHTML('afterbegin','<div class="loader loader-pop"></div>')
      this.DOM.el = document.querySelector('.loader')
      
    }
  }

  async hidePop(){
    if(this.device > 1){
      await gsap.to(this.DOM.el,{opacity:0,duration:.4,ease:Power2.easeInOut})
      this.DOM.el.remove()
    }
  }

  async hideIntro(template = ''){


    this.rotate.value = (360 - (this.rotate.value%360)) + this.rotate.value + 360
    // if(this.tlstart.progress()!=1){


      // gsap.to(this.DOM.numtext,{yPercent:this.numtextpos,duration:1,ease:Power3.easeInOut})
      // gsap.to(this.DOM.num,{yPercent:100,y:-100+'vh',x:100+'vw',xPercent:-100,duration:1,ease:Power3.easeInOut})
    
      // await gsap.to(this.DOM.prog,{scaleX:1,duration:1})

    // }
    this.anim.pause()

    if(this.device > 1){

      gsap.to(this.DOM.el.querySelector('#logop2'),{rotate:this.rotate.value+'deg',duration:.3})
      gsap.to(this.DOM.el.querySelector('.loader_box_line'),{scaleY:1,duration:.3,ease:Power2.easeInOut})
  
  
      gsap.to(this.DOM.el,{opacity:0,duration:.3,ease:Power2.easeInOut,
        onComplete:()=>{
          this.DOM.el.remove()
        }
      })
    }
    else{
      gsap.to(this.DOM.el.querySelector('.loader_box_line'),{scaleY:1,duration:.6,ease:Power2.easeInOut})
      await gsap.to(this.DOM.el.querySelector('#logop2'),{rotate:this.rotate.value+'deg',duration:.6})
      


      gsap.fromTo(this.DOM.el.querySelector('.loader_box'),{scale:.5},{scale:1,duration:1,ease:Power2.easeInOut})
      gsap.to(this.DOM.el,{opacity:0,duration:.4,ease:Power2.easeInOut,
        onComplete:()=>{
          this.DOM.el.remove()
        }
      })

  }

    
    // gsap.fromTo(document.querySelector('.mousebg_el'),{scale:.6,yoyo:true,duration:.6,ease:Power2.easeInOut})
    
    // await gsap.to(this.DOM.el,{opacity:0,duration:.6,ease:Power2.easeInOut})
   
  }

}

export default Loader
