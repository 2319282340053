
export default class {
  constructor (obj,device) {
    this.el = obj.el
    
    this.DOM = {
      el : this.el.parentNode,
    }

    this.device = device
    this.active = 0
    this.isupdate = 1
    
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      ratio:1
    }


    this.create()
  }

  
  


  create(){
    if(this.device > 1){
      this.animstick.start = (this.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      this.animstick.limit = (this.el.clientHeight + (this.h * 2)).toFixed(0)
    }else{
      this.animstick.start = (this.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      this.animstick.limit = (this.el.clientHeight + this.h).toFixed(0)
    }
    
    
    this.createAnim()
  }

  createAnim(){

    let gap = -8
    let pad = 5.6
    
    if(this.device > 1){
      this.anim = gsap.timeline({paused:true})    
      .fromTo(this.DOM.el,{"--cardpad": 0+'rem',"--cardbg": "#0A1A2B","--cardmain": "#fff"},{"--cardpad": pad+'rem',"--cardbg": "#FFC703","--cardmain": "#00101F",duration:.5,immediateRender:false},1)
      .fromTo(this.DOM.el,{"--cardpad": pad+'rem',"--cardbg": "#FFC703","--cardmain": "#00101F"},{"--cardpad": 0+'rem',"--cardbg": "#0A1A2B","--cardmain": "#fff",duration:.5,immediateRender:false},1.8)
    
    }else{
      this.anim = gsap.timeline({paused:true})    
      .fromTo(this.DOM.el.querySelector('.content_card_bg'),{left:0+'rem',right:0+'rem'},{left:gap+'rem',right:gap+'rem',duration:1,immediateRender:false},0)
      .fromTo(this.DOM.el.querySelector('.content_card_bg'),{left:gap+'rem',right:gap+'rem'},{left:0+'rem',right:0+'rem',duration:1,immediateRender:false},2)

      .fromTo(this.DOM.el.querySelector('.line_prog'),{left:0+'rem',right:0+'rem'},{left:gap+'rem',right:gap+'rem',duration:1,immediateRender:false},0)
      .fromTo(this.DOM.el.querySelector('.line_prog'),{left:gap+'rem',right:gap+'rem'},{left:0+'rem',right:0+'rem',duration:1,immediateRender:false},2)

      .fromTo(this.DOM.el,{"--cardpad": 0+'rem',"--cardbg": "#0A1A2B","--cardmain": "#fff"},{"--cardpad": pad+'rem',"--cardbg": "#FFC703","--cardmain": "#00101F",duration:.5,immediateRender:false},1)
      .fromTo(this.DOM.el,{"--cardpad": pad+'rem',"--cardbg": "#FFC703","--cardmain": "#00101F"},{"--cardpad": 0+'rem',"--cardbg": "#0A1A2B","--cardmain": "#fff",duration:.5,immediateRender:false},1.8)
    
    }
  
  }

  check(entry,pos){
    if(entry.isIntersecting == undefined){
      return false
    }
    
    let vis = entry.isIntersecting

    if(vis){
      this.start()


    }
    else{
      this.stop()
    }

    return vis

  }
  async start(){
    if(this.active == 1){
      return false
    }
    this.active = 1
    

    

  }
  
  stop(){
    this.active = 0
    
  }

  initEvents(){

  }
  removeEvents(){

  }

  update(pos){
  
    if(!this.anim){
      return false
    }
    // this.anim.progress(this.anim.current)
    this.animstick.current = (this.h+pos) - this.animstick.start
    this.animstick.current = gsap.utils.clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
    
    this.anim.progress(this.animstick.prog)
  }

  onResize(){
    this.h = window.innerHeight
    this.animstick.start = ( this.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = ((this.h + this.el.clientHeight) / 1.3).toFixed(0)
    // this.anim.killTweensOf(this.DOM.hold)
    if(this.anim){
      this.anim.kill()
      this.createAnim()
      this.update(window.scrollY)
    }
  }
  
  
}
