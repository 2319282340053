
import './index.scss'

class Nav {
  constructor (main) {
   
    this.main = main
    this.isOpen = 0

  }

  async create (temp) {

    document.querySelector('body').insertAdjacentHTML('afterbegin',temp)

   
    this.DOM = {
      el:document.querySelector('.nav'),
      burger:document.querySelector('.nav_burger'),
      sub:document.querySelector('.nav .Sub'),
      follow:document.querySelector('.nav .followEl')
    }

    this.maxY = this.DOM.sub.querySelector('.SubBox').getBoundingClientRect().height - this.DOM.follow.getBoundingClientRect().height - 12
    this.followOffsetY =  this.DOM.follow.getBoundingClientRect().y + this.DOM.follow.getBoundingClientRect().height*.5 
    

    gsap.set(this.DOM.el,{opacity:0})
    


    this.initEvents()
  }
  async openMenu(){
    

    document.documentElement.classList.add('act-menu')
    document.dispatchEvent(this.main.events.openmenu)
  }
  async closeMenu(){
    document.documentElement.classList.remove('act-menu')
    document.dispatchEvent(this.main.events.closemenu)
    
  }

  async show(){

    gsap.fromTo(this.DOM.el,{opacity:0},{opacity:1,ease:Power2.easeInOut,duration:.6})
    
  }
  async hide(){
    
    gsap.to(this.DOM.el,{opacity:0,ease:Power2.easeInOut,duration:.6})
  }

  initEvents(){
    this.DOM.burger.onclick = () =>{
      if(this.isOpen == 1){
        this.closeMenu()
        this.isOpen = 0
      }
      else{
        this.openMenu()
        this.isOpen = 1
      }
    }

    if(!this.main.isTouch){
      this.followY = gsap.quickTo(this.DOM.follow, "y", {duration: 1, ease: "power2"})

      for(let a of this.DOM.sub.querySelectorAll('a')){
        a.onmouseenter = (ev) =>{
          console.log(ev)
          let followy = a.getBoundingClientRect().y - this.DOM.sub.querySelector('.SubBox').getBoundingClientRect().y
          this.followY(followy)
          console.log(a.getBoundingClientRect().y - this.DOM.sub.getBoundingClientRect().y)
        }
      }

      // this.DOM.sub.querySelector('.SubBox').onmousemove = (ev) =>{
      //   let followy = Math.max(12,(ev.clientY - this.followOffsetY))
      //   followy = Math.min(followy,this.maxY)
      //   this.followY(followy)
      // }
    
    }
   
    this.DOM.sub.querySelector('.SubClick').onclick = () =>{
      if(this.DOM.sub.classList.contains('act')){
        this.DOM.sub.classList.remove('act')
      }
      else{
        this.DOM.sub.classList.add('act')
      }
    }
    
  }
  

  onResize(){
    this.followOffsetY = this.DOM.follow.getBoundingClientRect().y - this.DOM.follow.getBoundingClientRect().height*.5
    
    this.maxY = this.DOM.sub.querySelector('.SubBox').getBoundingClientRect().height - this.DOM.follow.getBoundingClientRect().height - 12
  }

}



export default Nav