
import Field from '/atoms🧿🧿🧿/Field'
import Chk from '/atoms🧿🧿🧿/Chk'
import Selc from '/atoms🧿🧿🧿/Selc'
import File from '/atoms🧿🧿🧿/File'
import { load } from 'recaptcha-v3'

async function asyncFunction() {
  const recaptcha = await load('6LdjC8ImAAAAAFAxkHOv_myEdlsLBGddoa2rKMBG',{
    size:'invisible',
    autoHideBadge: true
})
  const token = await recaptcha.execute()
  // const aver = await recaptcha.recaptcha.getResponse()
  // console.log(recaptcha)
  // console.log(token)
  // console.log(aver)

  return token
}

export default class {
  constructor (el,url) {
    this.DOM = {
      el:el,
      form:el.querySelectorAll('form'),
      selectclick:el.querySelector('.el_click'),
      selectops:el.querySelectorAll('.el_hold button'),
      send:el.querySelectorAll('.btnSend'),
      interest:el.querySelector('#interest')
    }

    

    this.active = 0
    this.base = url
    this.create()
    asyncFunction()
  }

  async create(){
    this.fields = []
    
    for(let [i,a] of this.DOM.form.entries()){
      let arr = []
      if(a.querySelectorAll('.Afield')){
        for(let b of a.querySelectorAll('.Afield')){
          arr.push(new Field(b))
        }
      }
      if(a.querySelectorAll('.Afile')){
        for(let b of a.querySelectorAll('.Afile')){
          arr.push(new File(b))
        }
      }
      if(a.querySelectorAll('.Aselect')){
        for(let b of a.querySelectorAll('.Aselect')){
          arr.push(new Selc(b))
        }
      }
      if(a.querySelectorAll('.Achk')){
        for(let b of a.querySelectorAll('.Achk')){
          arr.push(new Chk(b))
        }
      }


      this.fields.push(arr)
    }
  }
  async sendMail(){
    for(let a of this.fields[this.formsel]){
      a.check()
    }

    if(this.DOM.form[this.formsel].querySelector('.err')){
      return false
    }
    let info = []
    let formData = new FormData()

    info.push({label:'Interest', value:this.DOM.interest.value})
    for(let a of this.fields[this.formsel]){
      if(a.DOM.el.classList.contains('Afile')){
        // let ob = {label:a.DOM.npt.name.slice(0, -1), value:a.DOM.npt.value}
        // info.push(ob)
        formData.set('file',a.file,a.file.name)
      }
      else if(!a.DOM.el.classList.contains('chk')){
        let ob = {label:a.DOM.npt.name.slice(0, -1), value:a.DOM.npt.value}
        info.push(ob)
        
      }
    }

    const captcha = await asyncFunction()
    let ob = {label:'captcha', value:captcha}
    info.push(ob)


    formData.set("form", JSON.stringify(info))
    // console.log(formData)
    // console.log(info)
    // console.log(JSON.stringify(info))

    await fetch(this.base+'/wp-json/wp/v2/sendform',{
        
      method: 'post',
      body: formData
      // contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
      // processData: false, // NEEDED, DON'T OMIT THIS
    }).then((data)=>{
      // this.closeFn()
      this.DOM.send[this.formsel].classList.add('dis')
    })
  }
  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  async doneFn(){

  }
  initEvents(){
    for(let [i,el] of this.DOM.selectops.entries()){
      el.onclick = () =>{
        console.log(i)
        if(this.DOM.selectclick.parentNode.querySelector('.el_box button.act')){
          this.DOM.selectclick.parentNode.querySelector('.el_box button.act').classList.remove('act')
        }

        this.DOM.selectclick.classList.add('act')

        this.DOM.selectclick.querySelector('.el_click_v').innerHTML = el.innerText
        this.DOM.interest.value = el.innerText
        this.choseForm(i)
        el.classList.add('act') 
        this.DOM.selectclick.parentNode.classList.remove('act')
        this.DOM.form[this.formsel].classList.add('act') 
      }
    }
    this.DOM.selectclick.onclick = () =>{
      if(this.DOM.selectclick.parentNode.classList.contains('act')){
        this.DOM.selectclick.parentNode.classList.remove('act')

      }
      else{
        this.DOM.selectclick.parentNode.classList.add('act')

      }
    }
    for(let a of this.DOM.form){
      a.onsubmit = async (ev) =>{
        //Mandar form
        ev.preventDefault()
        this.sendMail()
      }
    }
    
  }
  choseForm(i){
    if(this.formsel!=undefined){
      this.DOM.send[this.formsel].classList.remove('dis')
      this.DOM.form[this.formsel].classList.remove('act')
      console.log(this.DOM.form[this.formsel])
    }
    console.log(this.DOM.form)
    this.formsel = i

    

  }
  removeEvents(){
   

  }
  
  onResize(){
  }
}
