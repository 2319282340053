
export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
    }
    this.device = device
    this.active = 0
    this.create()
  }

  async create(){
    
    this.anim = gsap.timeline({paused:true})
    if(this.device > 1){
      this.anim
      .set(this.DOM.el,{opacity:1},0)
      
    }
    else{
      this.anim
      .fromTo(this.DOM.el,{opacity:0},{opacity:1,duration:.6,delay:.8,ease:Power2.easeInOut},0)
    }
    


    
  
  }
  
  async start(){
    this.anim.play()
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
