
//import SplitType from 'split-type'


// import './index.scss'

export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
   

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      tt2:obj.el.parentNode.querySelector('.tt2'),
      tlbl:obj.el.parentNode.querySelector('.tlabel')
     
    }


    this.canrepeat = 0
    this.active = 0
    this.create()
  }
  
  create(){
    this.split = new window.SplitType(this.DOM.tt2, {types: 'words,lines,chars' })
    this.anim = gsap.timeline({
      paused:true,
      onComplete:()=>{
      this.canrepeat = 1,
      this.animrep.play()
      }
    })
    .fromTo(this.DOM.tlbl,{opacity:0},{opacity:1,duration:1},0)
    .fromTo(this.DOM.tt2,{opacity:0,y:2.4+'rem'},{opacity:1,y:0,duration:1},0)

    const charsc = this.DOM.tt2.querySelectorAll('span .char')
    this.animrep = gsap.timeline({paused:true,repeat:-1,repeatDelay:1})
    for(let [i,a] of charsc.entries()){
      this.animrep
      .fromTo(a,{color:'#fff'},{color:'#FFC703',duration:.4,immediateRender:false},i*.02)
      .fromTo(a,{color:'#FFC703'},{color:'#fff',duration:1,immediateRender:false},(i*.02)+1)
    }
  
  }


  

  check(entry,pos){
    let vis = false
    
      pos = Math.floor(pos)
      

      if(entry.isIntersecting == true){
       
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }
  
  start(){
    // console.log('start intro')
    if(this.active==1){
      return false
    }
    this.active = 1
    this.anim.play()
    if(this.canrepeat == 1){
      this.animrep.play()

    }
  }
  
  stop(){
    if(this.active==0){
      return false
    }
    this.active = 0
    if(this.canrepeat == 1){
      this.animrep.pause()
      this.animrep.progress(0)
    }

    // console.log('stop intro'+this.active)

    // console.log('stop intro change'+this.active)

   

  }

  initEvents(){
   

  }
  removeEvents(){

  }
  touchScroll(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
