
//import SplitType from 'split-type'


// import './index.scss'

export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
    this.el.parentNode.setAttribute('id','stck'+obj.pos)

    if(this.el.dataset.prior){
      this.prior = parseInt(this.el.dataset.prior)
    }

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      parent:document.querySelector('#stck'+obj.pos),
      set:document.querySelector('#stck'+obj.pos+' .stck_move'),
      
    }


    this.active = 0
    this.isupdate = 1
    
    
    this.h = window.innerHeight
    


    // this.navhead = document.querySelector('.nav_head')

    this.create()
  }
  
  create(){

      this.stick = {
        active:0,
        current:0,
        limit:0,
        start:0,
        prog:0
      }

      this.animstick = {
        active:0,
        current:0,
        limit:0,
        start:0,
        prog:0,
      }

      //TOP
     
      this.anim = gsap.timeline({paused:true})
      // .fromTo(this.DOM.bg,{scaleX:0,scaleY:.2},{scaleX:1,scaleY:1,duration:1,ease:Power2.easeInOut},0)
      // .to(this.DOM.bg,{opacity:1,duration:4},0)
      
    
  }
  check(entry,pos){
    let vis = false
    if(!this.stick ){
      return false
    }

      this.onResize(window.scrollY)
      pos = Math.floor(pos)
      

      if(entry.isIntersecting == true){
        vis = true
        document.documentElement.classList.add('scroll-stick')
        this.start()

      }
      else{
        document.documentElement.classList.remove('scroll-stick')
        this.stop()
      }
    return vis

  }
  
  start(){
    // //console.log('start intro')
    this.active = 1
  }
  
  stop(){
    // //console.log('stop intro'+this.active)

    
    this.active = 0
    // //console.log('stop intro change'+this.active)

   

  }

  initEvents(){
   

  }
  removeEvents(){

  }
  touchScroll(){

  }
  update(pos){
    if(!this.stick ){
      return false
    }
    if(this.touch==1){
      pos = window.scrollY
    }
      // this.stick.current = pos - this.stick.start
      // this.stick.current = clamp(0, this.stick.limit, this.stick.current)
      // this.stick.prog = this.stick.current / this.stick.limit

      this.animstick.current = pos - this.animstick.start
      this.animstick.current = gsap.utils.clamp(0, this.animstick.limit, this.animstick.current)
      this.animstick.prog = this.animstick.current  / this.animstick.limit
      this.anim.progress(this.animstick.prog)
      
      
      this.DOM.set.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0,  -${this.animstick.current}, 0, 0, 1)`
      
  }

  onResize(pos){

    this.w = window.innerWidth
    this.h = window.innerHeight

      this.animstick.start =  (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      this.animstick.limit = this.DOM.set.clientWidth - this.w



      this.DOM.el.style.height = this.animstick.limit+this.h+'px'

      // this.stick.start = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
      // this.stick.limit = this.DOM.el.clientWidth + this.DOM.els.clientHeight - this.h
    
  }
  
  
}
