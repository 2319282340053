
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      tt:el.querySelector('.ttj'),
      grad:el.querySelector('.grad'),
    }
    this.device = device
    this.active = 0
    this.create()
  }

  async create(){
    
    this.anim = gsap.timeline({paused:true})
    if(this.device > 1){
      this.anim
      .set(this.DOM.tt,{opacity:1},0)
      .fromTo(this.DOM.grad,{opacity:0},{opacity:1,duration:2,delay:0,ease:Power2.easeInOut},0)
      // .to(this.DOM.el.querySelector('.cone'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
      // .to(this.DOM.el.querySelector('.torus'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
    }
    else{
      this.anim
      .fromTo(this.DOM.tt,{opacity:0},{opacity:1,duration:.6,delay:.8,ease:Power2.easeInOut},0)
      .fromTo(this.DOM.grad,{opacity:0},{opacity:1,duration:2,delay:0,ease:Power2.easeInOut},0)
      .to(this.DOM.el.querySelector('.cone'),{yPercent:200,duration:2,delay:1,ease:Power2.easeOut},0)
      .to(this.DOM.el.querySelector('.torus'),{yPercent:-150,duration:2,delay:1,ease:Power2.easeOut},0)
    }
    


    
  
  }
  
  async start(){
    this.anim.play()
    this.DOM.tt.classList.add('act')
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
