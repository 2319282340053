function browserCheck(){
  //No memoria scroll
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }
  
  //🍫
  //--🪟 2
  //--🖼️ 1
  //🖥️ 0
  //📲 3

  // Tablets
  //el L-andscape ( 1 ) se trata como un desktop, recibe imágenes tamaño mobile ( < 1366 )
  //el P-ortrait ( 2 ) se trata como móvil, recibe imágenes < 1366
  
  //Mobile
  // Solo P-ortrait ( 3 ), coge imágenes portrait.


  //Imágenes
  // Si son mayores que 0 ( desktop ), coge el tamaño pequeño ( < 1366)




  // TOUCH
  var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || window.innerWidth < 1181;
  
  if(!isTouch){
    var devicec = 'desktop'
    var devnum = 0
    document.documentElement.classList.add("smooth")
  }
  else{
    var  devicec = 'mobile'
    var devnum = 3
    if(window.innerWidth > 767){
      if(window.innerWidth > window.innerHeight){
        devicec = 'tabletL'
        devnum = 1
      }
      else{
        devicec = 'tabletS'
        devnum = 2
      }
    }
    
    document.documentElement.classList.add("touch")
    document.documentElement.classList.add(devicec)
  }
  

 

  //WebP

  const element = document.createElement('canvas')
  let isWebPCheck = false
  let file = ''
  if (element.getContext && element.getContext('2d')) {
    isWebPCheck = element.toDataURL('image/webp').indexOf('data:image/webp') === 0
    
  }

  let isWebMCheck = true
  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') != -1) {
    
    if (ua.indexOf('chrome') > -1) {
    } 
    else {
      isWebMCheck = false
    }
  }


  if(ua.indexOf('firefox') > -1){
    document.documentElement.classList.add('CBff')
  }

  return {
    'deviceclass':devicec,
    'device':devnum,
    'isTouch':isTouch,
    'webp':+isWebPCheck,
    'webm':+isWebMCheck,
  }
}

function revCheck (){
  const checkfix = document.createElement('div')
  checkfix.className = 'checkfix'
  checkfix.insertAdjacentHTML('afterbegin','<div class="checkfix_t"></div>')
  
  document.querySelector('body').appendChild(checkfix)
  let ratio = ((window.innerHeight*10)/window.innerWidth).toFixed(2)
  let zoom = window.innerWidth != window.outerWidth
  document.querySelector('.checkfix_t').innerHTML = 'Width: '+window.innerWidth+'<br>Height: '+window.innerHeight+'<br>Ratio: 10/'+ratio+'<br>Zoom: '+zoom

  window.addEventListener('resize',()=>{

    let zoom = window.innerWidth != window.outerWidth
    let ratio = ((window.innerHeight*10)/window.innerWidth).toFixed(2)
    document.querySelector('.checkfix_t').innerHTML = 'Width: '+window.innerWidth+'<br>Height: '+window.innerHeight+'<br>Ratio: 16/'+ratio+'<br>Zoom: '+zoom
  })
}

export default { browserCheck, revCheck }
