
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      tt:el.querySelector('h2'),
      tl:el.querySelector('.Abtn'),
      grad:el.querySelector('.grad'),
    }
    this.device = device
    this.active = 0
    this.create()
  }

  async create(){
    
    this.anim = gsap.timeline({paused:true})
    if(this.device > 1){
      this.anim
      .set(this.DOM.tt,{opacity:1},0)
      .fromTo(this.DOM.grad,{opacity:0},{opacity:1,duration:2,delay:0,ease:Power2.easeInOut},0)
      // .to(this.DOM.el.querySelector('.cone'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
      // .to(this.DOM.el.querySelector('.torus'),{yPercent:-100,duration:.6,delay:.4,ease:'none'},0)
    }
    else{
      this.anim
      .fromTo(this.DOM.tl,{opacity:0},{opacity:1,duration:.6,delay:.6,ease:Power2.easeInOut},0)
      .fromTo(this.DOM.tt,{opacity:0},{opacity:1,duration:.6,delay:.8,ease:Power2.easeInOut},0)
      .fromTo(this.DOM.grad,{opacity:0},{opacity:1,duration:2,delay:0,ease:Power2.easeInOut},0)
      .to(this.DOM.el.querySelector('.cone'),{y:0,x:0,duration:4,delay:1,ease:Power2.easeOut},0)
      .to(this.DOM.el.querySelector('.torus'),{x:0,y:0,duration:6,delay:1,ease:Power2.easeOut},2)
      .to(this.DOM.el.querySelector('.semi'),{y:0+'vw',x:0+'vw',duration:8,delay:.4,ease:Power2.easeOut},4)
    }
    


    
  
  }
  
  async start(){
    this.anim.play()
    this.DOM.tt.classList.add('act')
  }
  initEvents(){

  }

  removeEvents(){
    
  }

  onResize(){
  }
}
