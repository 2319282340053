
//import SplitType from 'split-type'


// import './index.scss'

export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
   

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      tt:obj.el.parentNode.querySelector('.tt1d'),
      btn:obj.el.parentNode.querySelector('.Abtn'),
      bg:obj.el.parentNode.querySelector('.bg'),
     
    }


    this.canrepeat = 0
    this.active = 0
    this.create()
  }
  
  create(){

    if(!this.DOM.tt.querySelector('span')){
      return false
    }
    this.split = new window.SplitType(this.DOM.tt.querySelector('span'), {types: 'chars' })
    

    this.DOM.charsc = this.DOM.tt.querySelectorAll('span .char')

    this.hoverout = gsap.timeline({paused:true,onComplete:()=>{console.log('outy')}})
    .to(this.DOM.tt,{color:'#00101F',duration:.4,immediateRender:false},0)
    .to(this.DOM.tt.querySelector('span'),{color:'#00101F',duration:.4,immediateRender:false},0)
    this.hoverin = gsap.timeline({paused:true,onComplete:()=>{console.log('in')}})
    .to(this.DOM.tt,{color:'#fff',duration:.4,immediateRender:false},0)
    .to(this.DOM.tt.querySelector('span'),{color:'#FFC703',duration:.4,immediateRender:false},0)

    // this.animrep = gsap.timeline({paused:true,repeat:-1,repeatDelay:1})
    // for(let [i,a] of this.DOM.charsc.entries()){
    //   this.animrep
    //   .fromTo(a,{color:'#fff'},{color:'#FFC703',duration:.3,immediateRender:false},(i*.02)+.4)
    //   .fromTo(a,{color:'#FFC703'},{color:'#fff',duration:.6,immediateRender:false},((i*.02)+1))
    
    //   this.hoverout.to(a,{color:'#00101F',duration:.4,immediateRender:false},0)
    //   this.hoverin.to(a,{color:'#fff',duration:.4,immediateRender:false},0)
    // }
   
  
  }


  

  check(entry,pos){
    let vis = false
    
      pos = Math.floor(pos)
      

      if(entry.isIntersecting == true){
       
        this.start()

      }
      else{
        this.stop()
      }
    return vis

  }
  
  start(){
    // console.log('start intro')
    if(this.active==1){
      return false
    }
    this.active = 1
    
    // this.animrep.play()
  }
  
  stop(){
    if(this.active==0){
      return false
    }
    this.active = 0
    // this.animrep.play()

    // console.log('stop intro'+this.active)

    // console.log('stop intro change'+this.active)

   

  }

  initEvents(){

    this.rotX = gsap.quickTo(this.DOM.bg, "rotationX", {duration: .4,ease: "power2"})
    this.rotY = gsap.quickTo(this.DOM.bg, "rotationY", {duration: .4,ease: "power2"})
    // this.rotZ = gsap.quickTo(this.DOM.bg, "rotationZ", {duration: .4,ease: "power2"})
    
   
    this.mouseEnterFn = () =>{
      
      this.DOM.el.parentNode.classList.add('act')
      if(this.hoverout){
        this.hoverout.progress(0)
        this.hoverout.pause()
      }
      this.hoverin.play()
      this.animrep.progress(0)
      // this.animrep.play()

    }
    this.DOM.btn.addEventListener('mouseenter',this.mouseEnterFn)

    // this.mouseMoveFn = (e) =>{
    //   let w = this.DOM.btn.clientWidth
    //   let h = this.DOM.btn.clientHeight
    //   let b = this.DOM.btn.getBoundingClientRect()
    //   let X = (e.clientX - b.left) / w
    //   let Y = (e.clientY - b.top) / h
    
    //   let rX = -(X - 0.5) * 46
    //   let rY = (Y - 0.5) * 32
      
    //   // gsap.set(this.DOM.bg,{rotateX:rX+'deg'})
    //   // gsap.set(this.DOM.bg,{rotateY:rY+'deg'})
    
    //   // console.log(X, Y)

    //   this.rotX(rX)
    //   this.rotY(rY)
    //   // this.rotZ(rY)
    // }
    // this.DOM.btn.addEventListener('mousemove',(e) => this.mouseMoveFn(e))
    
    this.mouseleaveFn = () =>{
      this.DOM.el.parentNode.classList.remove('act')

      // this.rotX(0)
      // this.rotY(0)
      // this.animrep.pause()
      if(this.hoverin){
        this.hoverin.pause()
        this.hoverin.progress(0)
      }
      this.hoverout.play()
      
      
    }
    this.DOM.btn.addEventListener('mouseleave',this.mouseleaveFn)
  }


  removeEvents(){

  }
  touchScroll(){

  }
  update(pos){
   
  }

  onResize(pos){

  }
  
  
}
